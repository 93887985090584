import React from 'react';
import { Link } from 'react-router-dom';
import { GrMapLocation, GrHome, GrContact, GrStatusInfo } from 'react-icons/gr';
import NavLink from './NavLink';

import './MobileMenu.css';

const MobileMenu = () => {
    const logo = require('../../assets/images/logoLetters.png').default;

    return (
        <div className="mobile-menu">
            <Link id="mobile-menu-logo-link" to="/">
                <img className="mobile-menu-logo-icon" src={logo} alt="Compost Collective Logo"></img>
            </Link>

            {/* <NavLink icon={<GrStatusInfo />} title="About Us" /> */}
            <NavLink icon={<GrContact />} title="Contact Us" />
            {/* <NavLink icon={<GrHome />} title="Compost at Home" /> */}
            <NavLink icon={<GrMapLocation />} title="Where Things Go" />
            {/* <NavLink title="What we've done" /> */}
        </div>
    );
};

export default MobileMenu;