import React from 'react';

const AboutUs = () => {
    return (
        <div className="screen-content">
            <h1 className="screen-header">About Us</h1>

        </div>
    );
};

export default AboutUs;