import React, { useState } from 'react';
import { MapContainer as Map, TileLayer, Marker, Popup, Tooltip } from 'react-leaflet';
import './DropOffMap.css';

const renderMarker = (position, title, explanation, url) => {
    return (
        <Marker position={position} zIndexOffset={400}>
            <Tooltip permanent className="drop-off-tooltip">
                {title}
            </Tooltip>
            <Popup>
                {explanation} 
                <a href={url} target="_blank" rel="noopener noreferrer"> Click here to learn more</a>
            </Popup>
        </Marker>
    );
}

const DropOffMap = () => {
    const lat = 30.279;
    const long = -97.744;
    const position = [lat, long];

    const gosh = renderMarker([30.2511722, -97.5925611], 
        "Organics by Gosh", 
        'They charge a fee for drop off, but can take virtually limitless quanities.',
        'https://www.organicsbygosh.com/');

    const stLouisChurch = renderMarker([30.352692, -97.7340608], 
        "St. Louis Church", 
        "Free compost drop off ONLY when someone is oniste.",
        'https://www.st-louis.org/farm-ministry');

    return (
        <div className="drop-off-map-container leaflet-map-container">
            <Map 
                center={position} 
                zoom={10} 
                className="leaflet-map drop-off-map"
            >
                <TileLayer
                    attribution='&copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {gosh}
                {stLouisChurch}
            </Map>
        </div>
    );
};

export default DropOffMap;