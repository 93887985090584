import { 
  HashRouter as Router,
  Switch 
} from "react-router-dom";

import PublicRoute from './routes/PublicRoute';

import Toolbar from './components/navigation/Toolbar';
import MobileMenu from './components/navigation/MobileMenu';

import Home from './screens/Home';
import AboutUs from './screens/AboutUs';
import ContactUs from './screens/ContactUs';
import CompostAtHome from './screens/CompostAtHome';
import WhereThingsGo from './screens/WhereThingsGo';

import './App.css';

function App() {
  return (
    <Router>
      <Toolbar />
      <MobileMenu />
      <Switch>
        <PublicRoute path="/about-us" component={AboutUs} />
        <PublicRoute path="/contact-us" component={ContactUs} />
        {/* <PublicRoute path="/compost-at-home" component={CompostAtHome} /> */}
        <PublicRoute path="/where-things-go" component={WhereThingsGo} />
        <PublicRoute path="/" component={Home} />
      </Switch>
    </Router>
  );
}

export default App;
