import React from 'react';
import './Home.css';

const Home = () => {
    const gardenWaste = require('../assets/images/gardenWaste.jpg').default; // Image by <a href="https://pixabay.com/users/efraimstochter-12351/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=1047259">M W</a> from <a href="https://pixabay.com/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=1047259">Pixabay</a>
    const truck = require('../assets/images/truck.jpg').default; // Image by <a href="https://pixabay.com/users/prvideotv-2580458/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=1396587">prvideotv</a> from <a href="https://pixabay.com/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=1396587">Pixabay</a>
    const compost = require('../assets/images/compost.jpg').default; // Image by <a href="https://pixabay.com/users/ben_kerckx-69781/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=513609">Ben Kerckx</a> from <a href="https://pixabay.com/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=513609">Pixabay</a>

    return (
        <div className="screen-content home-container">
            <div className='screen-header'>
                <h1 className='home-header'>Our Mission and Business is Simple:</h1>
                <h2 className="subheader home-subheader">Stop Putting Organic Matter in Landfills!</h2>
            </div>
            
            <div className='box'>
                <h3>
                    Compost Collective is here to help eliminate organic waste in your home, multi-family or commerical property, or event! Central Texas knows it needs to get to Zero Waste. It's going to take teamwork from all of us to get there!
                </h3>
                <div className="mission-pics">
                <div className="mission-pic">
                    We make sure organic waste like this
                    <img
                        src={gardenWaste} 
                        alt="Compost bin cut across in the middle revealing decomposing organic matter"
                        className="garden-waste"
                    />
                </div>
                <div className="mission-pic">
                    Is able to decompose naturally for reuse
                    <img
                        src={compost} 
                        alt="Compost bin cut across in the middle revealing decomposing organic matter"
                        className="compost-mission-pic"
                    />
                </div>
                <div className="mission-pic">
                    And does not end up in a landfill
                    <img
                        src={truck} 
                        alt="A garbage collection truck and two people loading the truck"
                        className="truck"
                    />
                </div>
            </div>
            </div>
            
            <h2 className='subheader'>Why do we compost?</h2>
            <div className='box'>
                <p id="mission-explanation">
                    This fundumental issue arises because it is not possible for organic matter to biodegrade, or decompose naturally, in landfills.
                    Air, soil ecosystems, and microbes are required for these materials to break down naturally. 
                    Once it has been broken down properly, compost is a resource for farmers and gardeners alike to enrich the soil.
                    Landfills, on the other hand, are a threat to put toxins in local water tables and costs taxpayer dollars to manage.
                </p>
            </div>
            <h2 className='subheader'>Want to learn more?</h2>
            <div className='box'>
                <ul className="home-why">
                    <li>The University of California has published their thoughts <a target="_blank" rel="noopener noreferrer" href="https://anrcatalog.ucanr.edu/pdf/8367.pdf">here</a></li>
                    <li>Check out what Colorado University has to say <a target="_blank" rel="noopener noreferrer" href="https://www.colorado.edu/ecenter/zero-waste/compost/why-should-i-compost">here</a></li>
                    <li>Keep Austin Beautiful has included the subject in an article <a target="_blank" rel="noopener noreferrer" href="https://keepaustinbeautiful.org/blog/what-is-compost-why-does-it-matter/">here</a></li>
                    <li>A1 Organics, a Colorado-based organics company has some great content <a target="_blank" rel="noopener noreferrer" href="https://a1organics.com/resources/why-composting-matters/">here</a></li>
                </ul>
            </div>
        </div>
    );
};

export default Home;