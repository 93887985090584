import React from 'react';
import { Link } from 'react-router-dom';
import { GrMapLocation, GrHome, GrContact, GrStatusInfo } from 'react-icons/gr';
import NavLink from './NavLink';

import './Toolbar.css';

const Toolbar = () => {
    const logo = require('../../assets/images/logo.png').default;

    return (
        <header className="toolbar">
            <nav className="toolbar-nav">
                <Link to="/">
                    <img className="toolbar-logo-icon" src={logo} alt="Compost Collective Logo"></img>
                </Link>

                {/* <NavLink icon={<GrStatusInfo />} title="About Us" /> */}
                <NavLink icon={<GrContact />} title="Contact Us" />
                {/* <NavLink icon={<GrHome />} title="Compost at Home" /> */}
                <NavLink icon={<GrMapLocation />} title="Where Things Go" />
                {/* <NavLink title="What we've done" /> */}
            </nav>
        </header>
    );
};

export default Toolbar;