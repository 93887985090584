import React from 'react';
import './ContactUs.css';

const ContactUs = () => {
    return (
        <div className="screen-content contact-us-container">
            <h1 className="screen-header">Contact Us</h1>
            <div className='box'>
                <ul className='contact-us-list'>
                    <li>Please email <b>AustinCompostCollective@gmail.com</b> for quotes and all other business inquiries.</li>
                    <li>For apartments and commercial buildings, we can install our collection containers and establish a weekly pickup program.</li>
                    <li>For large events in the city, we are able to provide collection containers and haul them away when the event ends.</li>                
                </ul>
            </div>
        </div>
    );
};

export default ContactUs;