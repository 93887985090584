import React from 'react';
import DropOffMap from '../components/DropOffMap';
import './WhereThingsGo.css';

const WhereThingsGo = () => {
    return (
        <div className="screen-content">
            <h1 className="screen-header">So What Happens to the Compost we Collect?</h1>
            <div className="where-things-go-content box">
                <span>
                    We deliver all of the compost we collect to multiple locations throughout Austin. 
                    Our network is described and mapped below. 
                    Just click one of the markers on the map to learn more about that particular composting facility.
                </span>
                <DropOffMap />
            </div>
        </div>
    );
};

export default WhereThingsGo;