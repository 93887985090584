import React from 'react';
import { Link } from 'react-router-dom';

const NavLink = ({icon, title}) => {
    const lowercaseWords = title.toLowerCase().split(' ');
    const path = lowercaseWords.join('-');

    return (
        <Link to={path} className="nav-link">
            {icon}
            {title}
        </Link>
    );
};

export default NavLink;